import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

const Authentication = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (pathname === "sign_in") {
      return;
    }

    history.push("/sign_in");
  }, [pathname, history]);

  return <div hidden />;
};

export default Authentication;
