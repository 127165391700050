import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import styles from "./App.module.scss";
import Authentication from "./components/Authentication";
import SignIn from "./components/SignIn";

function App() {
  return (
    <div className={styles.app}>
      <BrowserRouter>
        <Authentication />
        <Switch>
          <Route path="/sign_in" component={SignIn} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
