import React from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import styles from "./SignIn.module.scss";

const SignIn = () => {
  return (
    <Container>
      <Row>
        <Col xs={12}>
          <h1 className={styles.Title}>Login</h1>
        </Col>
      </Row>
      <Form className={styles.Form}>
        <Form.Group>
          <Form.Control type="email" placeholder="Email" />
        </Form.Group>
        <Form.Group>
          <Form.Control type="password" placeholder="Password" />
        </Form.Group>
        <Form.Group>
          <div className="d-grid gap-2">
            <Button size="lg">Log in</Button>
          </div>
          <Button as={Link} size="sm" variant="link" to="/password/new">
            Forgot your password?
          </Button>
        </Form.Group>
      </Form>
    </Container>
  );
};

export default SignIn;
